/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Eye: THREE.Mesh;
  };
  materials: {
    M_02___Default: THREE.MeshStandardMaterial;
  };
};

export function Model(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("eye.glb") as any;
  return (
    <group
      {...props}
      dispose={null}
      scale={4000}
      rotation={[0, 0, 0]}
      position={[0, 0, -20]}
    >
      <group position={[-0.01, 0, 0.01]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Eye.geometry}
          material={materials.M_02___Default}
          scale={0.01}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/eye.glb");
