import { Suspense } from "react";
import ReactCardCarousel from "react-card-carousel";

import { RotatingLines } from "react-loader-spinner";

export default function LoadingComponent({
  desktopWidth,
}: {
  desktopWidth: boolean;
}) {
  return (
    <Suspense fallback={null}>
      <div className={"w-full h-full justify-end items-end"}>
        {desktopWidth && (
          <div className={"justify-center flex gap-8"}>
            <img src={"labyle3d.png"} width={400} />
          </div>
        )}
        {/*<div className={"h-[50%] w-[70%] items-center justify-center"}>
          <ReactCardCarousel
            autoplay={true}
            autoplay_speed={2500}
            spread="wide"
            disable_box_shadow
          >
            <div className={"w-100 h-100 bg-pink-400 rounded-full mx-24"}>
              <img
                src={"foto_sera.webp"}
                width={200}
                height={200}
                className={"rounded-full p-2"}
              />
            </div>
            <div className={"w-100 h-100 bg-pink-400 rounded-full mx-24"}>
              <img
                src={"foto_pitota.webp"}
                width={200}
                height={200}
                className={"rounded-full p-2"}
              />
            </div>
            <div className={"w-100 h-100 bg-pink-400 rounded-full mx-24"}>
              <img
                src={"foto_luana.webp"}
                width={200}
                height={200}
                className={"rounded-full p-2"}
              />
            </div>
            <div className={"w-100 h-100 bg-pink-400 rounded-full mx-24"}>
              <img
                src={"foto_cara.webp"}
                width={200}
                height={200}
                className={"rounded-full p-2"}
              />
            </div>
            <div className={"w-100 h-100 bg-pink-400 rounded-full mx-24"}>
              <img
                src={"foto_babix.webp"}
                width={200}
                height={200}
                className={"rounded-full p-2"}
              />
            </div>
          </ReactCardCarousel>
        </div>*/}
        <div className={"justify-center flex gap-8 mt-5"}>
          <RotatingLines
            strokeColor="grey"
            width="76"
            animationDuration={"1"}
          />
        </div>
      </div>
    </Suspense>
  );
}
