/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    SShape002: THREE.Mesh;
  };
  materials: {
    M_01___Default: THREE.MeshPhysicalMaterial;
  };
};

export function Model(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("La byle_logo_V2.glb") as any;
  return (
    <group {...props} dispose={null}>
      <group position={[0, 0.03, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.SShape002.geometry}
          material={materials.M_01___Default}
          scale={0.01}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/La byle_logo_V2.glb");
