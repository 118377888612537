import { useEffect, useState } from "react";
import Font from "react-font";
import { useMediaQuery } from "react-responsive";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MainContent from "./components/MainContent";

export default function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const [focusModal, setFocusModal] = useState("Home");

  const [width, setWindowWidth] = useState(0);

  const [desktopWidth, setDesktopWidth] = useState(true);

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
    setDesktopWidth(width > 1200);
  };

  console.log(desktopWidth);

  return (
    <>
      <Font family="Anton">
        <div
          className={
            "h-screen w-screen bg-gradient-to-r from-[#270762] via-[#6639d7] to-[#270762] items-center justify-center"
          }
        >
          <Header />
          <MainContent desktopWidth={desktopWidth} />
          <Footer />
        </div>
      </Font>
    </>
  );
}
