/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Speaker: THREE.Mesh;
  };
  materials: {
    M_03___Default: THREE.MeshStandardMaterial;
  };
};

export function Model(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("speaker.glb") as any;
  const [hover, setHover] = useState(false);
  return (
    <group
      {...props}
      dispose={null}
      scale={4000}
      rotation={[0, 0, 0]}
      position={[0, 0, -60]}
    >
      <group position={[-0.01, 0, 0.01]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.Speaker.geometry}
          material={materials.M_03___Default}
          scale={0.01}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/speaker.glb");
