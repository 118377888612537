import { Suspense, useEffect, useState } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { Box, OrbitControls } from "@react-three/drei";

import { Model as MenuText1_Listen } from "../assets/menu/Menu1_Listen";
import { Model as MenuText2_Watch } from "../assets/menu/Menu2_Watch";
import { Model as MenuText3_Show } from "../assets/menu/Menu3_Show";
import { Model as MenuText4_Contact } from "../assets/menu/Menu4_Contact";

import { Model as NameBabix } from "../assets/Name1";
import { Model as NameLuana } from "../assets/Name2";
import { Model as NamePitota } from "../assets/Name3";
import { Model as NameCara } from "../assets/Name4";
import { Model as NameSera } from "../assets/Name5";

import { Model as Logo } from "../assets/logo";

import {
  AnimationClip,
  Clock,
  ColorKeyframeTrack,
  LuminanceAlphaFormat,
  Texture,
  TextureLoader,
  Vector3,
} from "three";
import LoadingComponent from "./Loading";

/*import { Model as ModelBabix } from "../assets/characters/Group_Babix_V6";
import { Model as ModelCara } from "../assets/characters/Group_Cara";
import { Model as ModelLuana } from "../assets/characters/Group_Luana";
import { Model as ModelPitota } from "../assets/characters/Group_Pitota";
import { Model as ModelSera } from "../assets/characters/Group_Sera";*/

import { Model as ModelBabix } from "../assets/characters/Model_Babix";
import { Model as ModelCara } from "../assets/characters/Model_Cara";
import { Model as ModelLuana } from "../assets/characters/Model_Luana";
import { Model as ModelPitota } from "../assets/characters/Model_Pitota";
import { Model as ModelSera } from "../assets/characters/Model_Sera";

import logo3d from "labyle3d.png";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { randomInt } from "crypto";
import { randInt } from "three/src/math/MathUtils";

export default function MainContent({
  desktopWidth,
}: {
  desktopWidth: boolean;
}) {
  const [hoverSera, setHoverSera] = useState(false);
  const [hoverBabix, setHoverBabix] = useState(false);
  const [hoverLuana, setHoverLuana] = useState(false);
  const [hoverPitota, setHoverPitota] = useState(false);
  const [hoverCara, setHoverCara] = useState(false);

  const [showSera, setShowSera] = useState(true);
  const [showBabix, setShowBabix] = useState(false);
  const [showLuana, setShowLuana] = useState(false);
  const [showPitota, setShowPitota] = useState(false);
  const [showCara, setShowCara] = useState(false);

  const previousCharacter = () => {
    if (showSera) {
      setShowSera(false);
      setShowCara(true);
    } else if (showCara) {
      setShowCara(false);
      setShowPitota(true);
    } else if (showPitota) {
      setShowPitota(false);
      setShowLuana(true);
    } else if (showLuana) {
      setShowLuana(false);
      setShowBabix(true);
    } else if (showBabix) {
      setShowBabix(false);
      setShowSera(true);
    }
  };
  const nextCharacter = () => {
    if (showSera) {
      setShowSera(false);
      setShowBabix(true);
    } else if (showBabix) {
      setShowBabix(false);
      setShowLuana(true);
    } else if (showLuana) {
      setShowLuana(false);
      setShowPitota(true);
    } else if (showPitota) {
      setShowPitota(false);
      setShowCara(true);
    } else if (showCara) {
      setShowCara(false);
      setShowSera(true);
    }
  };

  const [hoverSponsor1, setHoverSponsor1] = useState(false);
  const [hoverSponsor2, setHoverSponsor2] = useState(false);
  const [hoverSponsor3, setHoverSponsor3] = useState(false);
  const [hoverSponsor4, setHoverSponsor4] = useState(false);

  return (
    <Suspense>
      <div className={"w-[100%] h-[90%] bg-opacity-0"}>
        {!desktopWidth && (
          <>
            <div
              className={
                "w-full h-[15%] items-center justify-center flex gap-10"
              }
            >
              <img src={"labyle3d.png"} height={200} width={400} />
            </div>
            <div
              className={
                "w-full h-[5%] pt-20 items-center justify-center flex gap-10"
              }
            >
              <div
                onClick={() => {
                  window.open(
                    "https://open.spotify.com/artist/6yWynwuKoYfoNCVpceBsGu?si=zVE6_CJ7RXGRauv7bBne6Q",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                LISTEN
              </div>
              <div
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/@LaByle",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                WATCH
              </div>
              <div
                onClick={() => {
                  window.open(
                    "https://www.bandsintown.com/a/15378047",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                SHOWS
              </div>
              <div
                onClick={() => {
                  window.open(
                    "mailto:info@labyle.com",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                CONTACT
              </div>
            </div>
          </>
        )}
        <div className="flex w-full h-[70%]">
          {!desktopWidth && (
            <div className={"justify-center items-center h-full w-[8%] flex"}>
              <FaArrowLeft
                size={70}
                onClick={previousCharacter}
                color={"#db2778"}
              />
            </div>
          )}
          <div className={`h-full ${!desktopWidth ? "w-[84%]" : "w-full"}`}>
            <Suspense
              fallback={<LoadingComponent desktopWidth={desktopWidth} />}
            >
              <Canvas>
                <pointLight position={[0, 40, 40]} />

                <OrbitControls
                  minPolarAngle={Math.PI / 4}
                  maxPolarAngle={(Math.PI * 7) / 16}
                  enableZoom={false}
                  maxAzimuthAngle={Math.PI / 6}
                  minAzimuthAngle={(Math.PI * 11) / 6}
                  enablePan={false}
                />

                <Logo
                  scale={4}
                  rotation={[(Math.PI * 3) / 7, 0, 0]}
                  position={[0, 1.7, -1.6]}
                  visible={desktopWidth}
                />

                <mesh
                  scale={0.07}
                  position={hoverSponsor1 ? [3.5, 1, -1.8] : [3.5, 1, -2]}
                  visible={desktopWidth}
                  onPointerOver={() => {
                    setHoverSponsor1(true);
                  }}
                  onPointerOut={() => {
                    setHoverSponsor1(false);
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.yabal.io/",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  <planeBufferGeometry attach="geometry" args={[10, 10]} />
                  <meshStandardMaterial
                    map={useLoader(TextureLoader, "/sponsor_yabalio.png")}
                    transparent
                  />
                </mesh>
                <mesh
                  scale={0.07}
                  position={hoverSponsor2 ? [-3.5, 1, -1.8] : [-3.5, 1, -2]}
                  visible={desktopWidth}
                  onPointerOver={() => {
                    setHoverSponsor2(true);
                  }}
                  onPointerOut={() => {
                    setHoverSponsor2(false);
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.berlin-music-commission.de/",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  <planeBufferGeometry attach="geometry" args={[20, 10]} />
                  <meshStandardMaterial
                    map={useLoader(
                      TextureLoader,
                      "/sponsor_berlinmusiccommission.webp"
                    )}
                    transparent
                  />
                </mesh>
                <mesh
                  scale={0.07}
                  position={hoverSponsor3 ? [5.0, 1, -1.8] : [5.0, 1, -2]}
                  visible={desktopWidth}
                  onPointerOver={() => {
                    setHoverSponsor3(true);
                  }}
                  onPointerOut={() => {
                    setHoverSponsor3(false);
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.initiative-musik.de",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  <planeBufferGeometry attach="geometry" args={[15, 10]} />
                  <meshStandardMaterial
                    map={useLoader(TextureLoader, "/sponsor_inimusik.png")}
                    transparent
                  />
                </mesh>
                <mesh
                  scale={0.07}
                  position={hoverSponsor4 ? [-5.0, 1, -1.8] : [-5.0, 1, -2]}
                  visible={desktopWidth}
                  onPointerOver={() => {
                    setHoverSponsor4(true);
                  }}
                  onPointerOut={() => {
                    setHoverSponsor4(false);
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.bundesregierung.de/breg-de/bundesregierung/bundeskanzleramt/staatsministerin-fuer-kultur-und-medien/kultur/neustart-kultur-1772990",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  <planeBufferGeometry attach="geometry" args={[14, 11]} />
                  <meshStandardMaterial
                    map={useLoader(TextureLoader, "/sponsor_neustart.png")}
                    transparent
                  />
                </mesh>

                <group position={[0, -0.6, 1.5]}>
                  {desktopWidth && (
                    <group position={desktopWidth ? [0, 0, 0] : [0, 2, 0]}>
                      <MenuText1_Listen
                        onClick={() => {
                          window.open(
                            "https://open.spotify.com/artist/6yWynwuKoYfoNCVpceBsGu?si=zVE6_CJ7RXGRauv7bBne6Q",
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                        //desktopWidth={desktopWidth}
                      />
                      <MenuText2_Watch
                        onClick={() => {
                          window.open(
                            "https://www.youtube.com/@LaByle",
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                        //desktopWidth={desktopWidth}
                      />
                      <MenuText3_Show
                        onClick={() => {
                          window.open(
                            "https://www.bandsintown.com/a/15378047",
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                        //desktopWidth={desktopWidth}
                      />
                      <MenuText4_Contact
                        onClick={() => {
                          window.open(
                            "mailto:info@labyle.com",
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                        //desktopWidth={desktopWidth}
                      />
                    </group>
                  )}
                  <group position={[0, 0, -1.5]}>
                    <group position={[0, 0, 1.5]} scale={0.9}>
                      <group
                        position={desktopWidth ? [0, 0, 0] : [1.6, 0, -0.8]}
                        scale={desktopWidth ? [1, 1, 1] : [1.5, 1.5, 1.5]}
                        visible={desktopWidth || showBabix}
                      >
                        <Suspense
                          fallback={
                            /*SuspenseIcon(
                            useLoader(TextureLoader, "/foto_babix.webp"),
                            desktopWidth,
                            [-1, 0.8, 0.7],
                            randInt(80, 100)
                          )*/
                            null
                          }
                        >
                          <ModelBabix setHover={setHoverBabix} />
                        </Suspense>
                      </group>
                      <group
                        position={desktopWidth ? [0, 0, 0] : [0.85, 0, 0.6]}
                        scale={desktopWidth ? [1, 1, 1] : [1.5, 1.5, 1.5]}
                        visible={desktopWidth || showCara}
                      >
                        <Suspense fallback={null}>
                          <ModelCara setHover={setHoverCara} />
                        </Suspense>
                      </group>
                      <group
                        position={desktopWidth ? [0, 0, 0] : [-1.5, 0, -1]}
                        scale={desktopWidth ? [1, 1, 1] : [1.5, 1.5, 1.5]}
                        visible={desktopWidth || showLuana}
                      >
                        <Suspense fallback={null}>
                          <ModelLuana setHover={setHoverLuana} />
                        </Suspense>
                      </group>
                      <group
                        position={desktopWidth ? [0, 0, 0] : [-0.85, 0, 0.6]}
                        scale={desktopWidth ? [1, 1, 1] : [1.5, 1.5, 1.5]}
                        visible={desktopWidth || showPitota}
                      >
                        <Suspense fallback={null}>
                          <ModelPitota setHover={setHoverPitota} />
                        </Suspense>
                      </group>
                      <group
                        position={desktopWidth ? [0, 0, 0] : [0, 0, -1]}
                        scale={desktopWidth ? [1, 1, 1] : [1.5, 1.5, 1.5]}
                        visible={desktopWidth || showSera}
                      >
                        <Suspense fallback={null}>
                          <ModelSera setHover={setHoverSera} />
                        </Suspense>
                      </group>
                    </group>
                    <mesh
                      scale={desktopWidth ? 0.045 : 0.04}
                      position={[0, 0, 1.3]}
                    >
                      <cylinderBufferGeometry
                        attach="geometry"
                        args={[40, 15, 1]}
                      />
                      <meshStandardMaterial
                        color={"#ec489a"}
                        //map={useLoader(TextureLoader, "/floor.gif")}
                      />
                    </mesh>
                  </group>
                </group>
              </Canvas>
            </Suspense>
          </div>
          {!desktopWidth && (
            <div className={"justify-center items-center h-full w-[8%] flex"}>
              <FaArrowRight
                size={70}
                onClick={nextCharacter}
                color={"#db2778"}
              />
            </div>
          )}
        </div>
        {/*!desktopWidth && (
        <div className="w-full h-[10%] flex items-center justify-center text-4xl font-serif">
          {showBabix && <>BABIX</>}
          {showSera && <>SERA</>}
          {showCara && <>CARA</>}
          {showLuana && <>LUANA</>}
          {showPitota && <>PITOTA</>}
        </div>
      )*/}
      </div>
    </Suspense>
  );
}

const SuspenseIcon = (
  icon: Texture,
  desktopWidth: boolean,
  position: [x: number, y: number, z: number],
  velocity: number
) => {
  const [rotation, setRotation] = useState(0);
  const [direction, setDirection] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (rotation < 1 && rotation > -1) {
        setRotation((val) => (direction ? val + 0.1 : val - 0.1));
      } else {
        setDirection((val) => !val);
        setRotation(direction ? 0.99 : -0.99);
      }
    }, velocity / 10);

    return () => clearInterval(interval);
  }, [rotation]);

  return (
    <mesh
      scale={desktopWidth ? 0.025 : 0.025}
      rotation={[0, 0, 0]}
      position={[
        position[0],
        position[1] + Math.PI * 0.02 * rotation,
        position[2],
      ]}

      //visible={!desktopWidth}
    >
      <circleBufferGeometry attach="geometry" args={[20, 15]} />
      <meshStandardMaterial map={icon} transparent />
    </mesh>
  );
};
