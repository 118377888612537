/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { Suspense, useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Body: THREE.SkinnedMesh;
    Bracelets: THREE.SkinnedMesh;
    Buckles: THREE.SkinnedMesh;
    Earrings: THREE.SkinnedMesh;
    Eyes: THREE.SkinnedMesh;
    Hair: THREE.SkinnedMesh;
    HairBands: THREE.SkinnedMesh;
    Jacke: THREE.SkinnedMesh;
    Laces: THREE.SkinnedMesh;
    Lashes: THREE.SkinnedMesh;
    Neckless: THREE.SkinnedMesh;
    Pants: THREE.SkinnedMesh;
    PantsPart: THREE.SkinnedMesh;
    Piercing: THREE.SkinnedMesh;
    Shirt: THREE.SkinnedMesh;
    Shoes: THREE.SkinnedMesh;
    Teeth_Lower: THREE.SkinnedMesh;
    Teeth_Upper: THREE.SkinnedMesh;
    Tongue: THREE.SkinnedMesh;
    mixamorigHips: THREE.Bone;
  };
  materials: {
    M_Body: THREE.MeshStandardMaterial;
    M_Accessories: THREE.MeshStandardMaterial;
    M_Clothing: THREE.MeshStandardMaterial;
    M_Hair: THREE.MeshStandardMaterial;
  };
  animations: GLTFAction[];
};

interface GLTFAction extends THREE.AnimationClip {
  name: ActionName;
}

type ActionName = "Armature|mixamo.com|Layer0";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export function Model({
  props,
  setHover,
}: {
  props?: JSX.IntrinsicElements["group"];
  setHover: any;
}) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials, animations } = useGLTF("/Model_Sera.gltf") as any;
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    actions["Armature|mixamo.com|Layer0"]?.play();
  });
  return (
    <Suspense fallback={null}>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <group
            name="Armature"
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.01}
            position={[0.2, 0, 0.84]}
          >
            <primitive object={nodes.mixamorigHips} />
            <skinnedMesh
              name="Body"
              geometry={nodes.Body.geometry}
              material={materials.M_Body}
              skeleton={nodes.Body.skeleton}
            />
            <skinnedMesh
              name="Bracelets"
              geometry={nodes.Bracelets.geometry}
              material={materials.M_Accessories}
              skeleton={nodes.Bracelets.skeleton}
            />
            <skinnedMesh
              name="Buckles"
              geometry={nodes.Buckles.geometry}
              material={materials.M_Clothing}
              skeleton={nodes.Buckles.skeleton}
            />
            <skinnedMesh
              name="Earrings"
              geometry={nodes.Earrings.geometry}
              material={materials.M_Accessories}
              skeleton={nodes.Earrings.skeleton}
            />
            <skinnedMesh
              name="Eyes"
              geometry={nodes.Eyes.geometry}
              material={materials.M_Body}
              skeleton={nodes.Eyes.skeleton}
            />
            <skinnedMesh
              name="Hair"
              geometry={nodes.Hair.geometry}
              material={materials.M_Hair}
              skeleton={nodes.Hair.skeleton}
            />
            <skinnedMesh
              name="HairBands"
              geometry={nodes.HairBands.geometry}
              material={materials.M_Accessories}
              skeleton={nodes.HairBands.skeleton}
            />
            <skinnedMesh
              name="Jacke"
              geometry={nodes.Jacke.geometry}
              material={materials.M_Clothing}
              skeleton={nodes.Jacke.skeleton}
            />
            <skinnedMesh
              name="Laces"
              geometry={nodes.Laces.geometry}
              material={materials.M_Accessories}
              skeleton={nodes.Laces.skeleton}
            />
            <skinnedMesh
              name="Lashes"
              geometry={nodes.Lashes.geometry}
              material={materials.M_Body}
              skeleton={nodes.Lashes.skeleton}
            />
            <skinnedMesh
              name="Neckless"
              geometry={nodes.Neckless.geometry}
              material={materials.M_Accessories}
              skeleton={nodes.Neckless.skeleton}
            />
            <skinnedMesh
              name="Pants"
              geometry={nodes.Pants.geometry}
              material={materials.M_Clothing}
              skeleton={nodes.Pants.skeleton}
            />
            <skinnedMesh
              name="PantsPart"
              geometry={nodes.PantsPart.geometry}
              material={materials.M_Clothing}
              skeleton={nodes.PantsPart.skeleton}
            />
            <skinnedMesh
              name="Piercing"
              geometry={nodes.Piercing.geometry}
              material={materials.M_Accessories}
              skeleton={nodes.Piercing.skeleton}
            />
            <skinnedMesh
              name="Shirt"
              geometry={nodes.Shirt.geometry}
              material={materials.M_Clothing}
              skeleton={nodes.Shirt.skeleton}
            />
            <skinnedMesh
              name="Shoes"
              geometry={nodes.Shoes.geometry}
              material={materials.M_Accessories}
              skeleton={nodes.Shoes.skeleton}
            />
            <skinnedMesh
              name="Teeth_Lower"
              geometry={nodes.Teeth_Lower.geometry}
              material={materials.M_Body}
              skeleton={nodes.Teeth_Lower.skeleton}
            />
            <skinnedMesh
              name="Teeth_Upper"
              geometry={nodes.Teeth_Upper.geometry}
              material={materials.M_Body}
              skeleton={nodes.Teeth_Upper.skeleton}
            />
            <skinnedMesh
              name="Tongue"
              geometry={nodes.Tongue.geometry}
              material={materials.M_Body}
              skeleton={nodes.Tongue.skeleton}
            />
          </group>
        </group>
      </group>
    </Suspense>
  );
}

useGLTF.preload("/Model_Sera.gltf");
