/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { Suspense, useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Body001: THREE.SkinnedMesh;
    Boots001: THREE.SkinnedMesh;
    Earrings001: THREE.SkinnedMesh;
    Eyes001: THREE.SkinnedMesh;
    Glasses001: THREE.SkinnedMesh;
    Hair002: THREE.SkinnedMesh;
    HairBand001: THREE.SkinnedMesh;
    Piercing001: THREE.SkinnedMesh;
    Piercing_003: THREE.SkinnedMesh;
    Top001: THREE.SkinnedMesh;
    mixamorigHips: THREE.Bone;
  };
  materials: {
    M_Body5: THREE.MeshStandardMaterial;
    M_Clothing5: THREE.MeshStandardMaterial;
    M_Accessories5: THREE.MeshStandardMaterial;
    M_Hair5: THREE.MeshStandardMaterial;
  };
  animations: GLTFAction[];
};

interface GLTFAction extends THREE.AnimationClip {
  name: ActionName;
}

type ActionName = "Armature|mixamo.com|Layer0";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export function Model({
  props,
  setHover,
}: {
  props?: JSX.IntrinsicElements["group"];
  setHover: any;
}) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials, animations } = useGLTF("/Model_Luana.gltf") as any;
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    actions["Armature|mixamo.com|Layer0"]?.play();
  });
  return (
    <Suspense fallback={null}>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <group
            name="Armature"
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.108}
            position={[1.11, -0.02, 0.4]}
          >
            <primitive object={nodes.mixamorigHips} />
            <skinnedMesh
              name="Body001"
              geometry={nodes.Body001.geometry}
              material={materials.M_Body5}
              skeleton={nodes.Body001.skeleton}
            />
            <skinnedMesh
              name="Boots001"
              geometry={nodes.Boots001.geometry}
              material={materials.M_Clothing5}
              skeleton={nodes.Boots001.skeleton}
            />
            <skinnedMesh
              name="Earrings001"
              geometry={nodes.Earrings001.geometry}
              material={materials.M_Accessories5}
              skeleton={nodes.Earrings001.skeleton}
            />
            <skinnedMesh
              name="Eyes001"
              geometry={nodes.Eyes001.geometry}
              material={materials.M_Body5}
              skeleton={nodes.Eyes001.skeleton}
            />
            <skinnedMesh
              name="Glasses001"
              geometry={nodes.Glasses001.geometry}
              material={materials.M_Accessories5}
              skeleton={nodes.Glasses001.skeleton}
            />
            <skinnedMesh
              name="Hair002"
              geometry={nodes.Hair002.geometry}
              material={materials.M_Hair5}
              skeleton={nodes.Hair002.skeleton}
            />
            <skinnedMesh
              name="HairBand001"
              geometry={nodes.HairBand001.geometry}
              material={materials.M_Accessories5}
              skeleton={nodes.HairBand001.skeleton}
            />
            <skinnedMesh
              name="Piercing001"
              geometry={nodes.Piercing001.geometry}
              material={materials.M_Accessories5}
              skeleton={nodes.Piercing001.skeleton}
            />
            <skinnedMesh
              name="Piercing_003"
              geometry={nodes.Piercing_003.geometry}
              material={materials.M_Accessories5}
              skeleton={nodes.Piercing_003.skeleton}
            />
            <skinnedMesh
              name="Top001"
              geometry={nodes.Top001.geometry}
              material={materials.M_Clothing5}
              skeleton={nodes.Top001.skeleton}
            />
          </group>
        </group>
      </group>
    </Suspense>
  );
}

useGLTF.preload("/Model_Luana.gltf");
