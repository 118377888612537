/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { Suspense, useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Pitota_Bag: THREE.SkinnedMesh;
    Pitota_Glasses: THREE.SkinnedMesh;
    Pitota_Hair: THREE.SkinnedMesh;
    Pitota_Hands: THREE.SkinnedMesh;
    Pitota_Legs: THREE.SkinnedMesh;
    Pitota_Pants: THREE.SkinnedMesh;
    Pitota_Shirt: THREE.SkinnedMesh;
    Pitota_Shoes: THREE.SkinnedMesh;
    Pitota_Watch: THREE.SkinnedMesh;
    Mesh001: THREE.SkinnedMesh;
    Mesh001_1: THREE.SkinnedMesh;
    mixamorigHips: THREE.Bone;
  };
  materials: {
    M_Accessories: THREE.MeshStandardMaterial;
    M_Accessories1: THREE.MeshStandardMaterial;
    ["01 - Default"]: THREE.MeshStandardMaterial;
    M_Clothes: THREE.MeshStandardMaterial;
    M_Face: THREE.MeshStandardMaterial;
    M_Hair: THREE.MeshStandardMaterial;
  };
  animations: GLTFAction[];
};

interface GLTFAction extends THREE.AnimationClip {
  name: ActionName;
}

type ActionName = "Armature|mixamo.com|Layer0";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export function Model({
  props,
  setHover,
}: {
  props?: JSX.IntrinsicElements["group"];
  setHover: any;
}) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials, animations } = useGLTF("/Model_Pitota.gltf") as any;
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    actions["Armature|mixamo.com|Layer0"]?.play();
  });
  return (
    <Suspense fallback={null}>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <group
            name="Armature"
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.105}
            position={[0.59, -0.1, -0.64]}
          >
            <primitive object={nodes.mixamorigHips} />
            <skinnedMesh
              name="Pitota_Bag"
              geometry={nodes.Pitota_Bag.geometry}
              material={materials.M_Accessories}
              skeleton={nodes.Pitota_Bag.skeleton}
            />
            <skinnedMesh
              name="Pitota_Glasses"
              geometry={nodes.Pitota_Glasses.geometry}
              material={materials.M_Accessories1}
              skeleton={nodes.Pitota_Glasses.skeleton}
            />
            <skinnedMesh
              name="Pitota_Hair"
              geometry={nodes.Pitota_Hair.geometry}
              material={materials["01 - Default"]}
              skeleton={nodes.Pitota_Hair.skeleton}
            />
            <skinnedMesh
              name="Pitota_Hands"
              geometry={nodes.Pitota_Hands.geometry}
              material={materials.M_Clothes}
              skeleton={nodes.Pitota_Hands.skeleton}
            />
            <skinnedMesh
              name="Pitota_Legs"
              geometry={nodes.Pitota_Legs.geometry}
              material={materials.M_Clothes}
              skeleton={nodes.Pitota_Legs.skeleton}
            />
            <skinnedMesh
              name="Pitota_Pants"
              geometry={nodes.Pitota_Pants.geometry}
              material={materials.M_Clothes}
              skeleton={nodes.Pitota_Pants.skeleton}
            />
            <skinnedMesh
              name="Pitota_Shirt"
              geometry={nodes.Pitota_Shirt.geometry}
              material={materials.M_Clothes}
              skeleton={nodes.Pitota_Shirt.skeleton}
            />
            <skinnedMesh
              name="Pitota_Shoes"
              geometry={nodes.Pitota_Shoes.geometry}
              material={materials.M_Accessories}
              skeleton={nodes.Pitota_Shoes.skeleton}
            />
            <skinnedMesh
              name="Pitota_Watch"
              geometry={nodes.Pitota_Watch.geometry}
              material={materials.M_Accessories}
              skeleton={nodes.Pitota_Watch.skeleton}
            />
            <group name="Pitota_Face_Neutral">
              <skinnedMesh
                name="Mesh001"
                geometry={nodes.Mesh001.geometry}
                material={materials.M_Face}
                skeleton={nodes.Mesh001.skeleton}
                morphTargetDictionary={nodes.Mesh001.morphTargetDictionary}
                morphTargetInfluences={nodes.Mesh001.morphTargetInfluences}
              />
              <skinnedMesh
                name="Mesh001_1"
                geometry={nodes.Mesh001_1.geometry}
                material={materials.M_Hair}
                skeleton={nodes.Mesh001_1.skeleton}
                morphTargetDictionary={nodes.Mesh001_1.morphTargetDictionary}
                morphTargetInfluences={nodes.Mesh001_1.morphTargetInfluences}
              />
            </group>
          </group>
        </group>
      </group>
    </Suspense>
  );
}

useGLTF.preload("/Model_Pitota.gltf");
