/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { Suspense, useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Babix_Body001: THREE.SkinnedMesh;
    Babix_Brows001: THREE.SkinnedMesh;
    Babix_Eyelight001: THREE.SkinnedMesh;
    Babix_Eyes001: THREE.SkinnedMesh;
    Babix_Face001: THREE.SkinnedMesh;
    Babix_Hair001: THREE.SkinnedMesh;
    Babix_Lashes001: THREE.SkinnedMesh;
    Babix_Shoes001: THREE.SkinnedMesh;
    Babix_Teeth_Lower001: THREE.SkinnedMesh;
    Babix_Teeth_Tounge001: THREE.SkinnedMesh;
    Babix_Teeth_Upper001: THREE.SkinnedMesh;
    mixamorigHips: THREE.Bone;
  };
  materials: {
    Material: THREE.MeshStandardMaterial;
    ["Material.004"]: THREE.MeshStandardMaterial;
    ["Material.003"]: THREE.MeshStandardMaterial;
    ["Material.002"]: THREE.MeshStandardMaterial;
    ["Material.001"]: THREE.MeshStandardMaterial;
    ["Material.005"]: THREE.MeshStandardMaterial;
    ["Material.006"]: THREE.MeshStandardMaterial;
  };
  animations: GLTFAction[];
};

interface GLTFAction extends THREE.AnimationClip {
  name: ActionName;
}

type ActionName =
  | "Armature.001|mixamo.com|Layer0"
  | "Armature|Take 001|BaseLayer";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export function Model({
  props,
  setHover,
}: {
  props?: JSX.IntrinsicElements["group"];
  setHover: any;
}) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials, animations } = useGLTF("/Model_Babix.gltf") as any;
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    actions["Armature.001|mixamo.com|Layer0"]?.play();
  });
  return (
    <Suspense fallback={null}>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <group
            name="Armature001"
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.11}
            position={[-1.15, 0, 0.39]}
          >
            <primitive object={nodes.mixamorigHips} />
            <skinnedMesh
              name="Babix_Body001"
              geometry={nodes.Babix_Body001.geometry}
              material={materials.Material}
              skeleton={nodes.Babix_Body001.skeleton}
            />
            <skinnedMesh
              name="Babix_Brows001"
              geometry={nodes.Babix_Brows001.geometry}
              material={materials["Material.004"]}
              skeleton={nodes.Babix_Brows001.skeleton}
            />
            <skinnedMesh
              name="Babix_Eyelight001"
              geometry={nodes.Babix_Eyelight001.geometry}
              material={nodes.Babix_Eyelight001.material}
              skeleton={nodes.Babix_Eyelight001.skeleton}
            />
            <skinnedMesh
              name="Babix_Eyes001"
              geometry={nodes.Babix_Eyes001.geometry}
              material={materials["Material.003"]}
              skeleton={nodes.Babix_Eyes001.skeleton}
            />
            <skinnedMesh
              name="Babix_Face001"
              geometry={nodes.Babix_Face001.geometry}
              material={materials["Material.002"]}
              skeleton={nodes.Babix_Face001.skeleton}
            />
            <skinnedMesh
              name="Babix_Hair001"
              geometry={nodes.Babix_Hair001.geometry}
              material={materials["Material.001"]}
              skeleton={nodes.Babix_Hair001.skeleton}
            />
            <skinnedMesh
              name="Babix_Lashes001"
              geometry={nodes.Babix_Lashes001.geometry}
              material={materials["Material.005"]}
              skeleton={nodes.Babix_Lashes001.skeleton}
            />
            <skinnedMesh
              name="Babix_Shoes001"
              geometry={nodes.Babix_Shoes001.geometry}
              material={materials["Material.006"]}
              skeleton={nodes.Babix_Shoes001.skeleton}
            />
            <skinnedMesh
              name="Babix_Teeth_Lower001"
              geometry={nodes.Babix_Teeth_Lower001.geometry}
              material={nodes.Babix_Teeth_Lower001.material}
              skeleton={nodes.Babix_Teeth_Lower001.skeleton}
            />
            <skinnedMesh
              name="Babix_Teeth_Tounge001"
              geometry={nodes.Babix_Teeth_Tounge001.geometry}
              material={nodes.Babix_Teeth_Tounge001.material}
              skeleton={nodes.Babix_Teeth_Tounge001.skeleton}
            />
            <skinnedMesh
              name="Babix_Teeth_Upper001"
              geometry={nodes.Babix_Teeth_Upper001.geometry}
              material={nodes.Babix_Teeth_Upper001.material}
              skeleton={nodes.Babix_Teeth_Upper001.skeleton}
            />
          </group>
        </group>
      </group>
    </Suspense>
  );
}

useGLTF.preload("/Model_Babix.gltf");
