/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { Suspense, useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { GLTF } from "three-stdlib";

type GLTFResult = GLTF & {
  nodes: {
    Arms: THREE.SkinnedMesh;
    B: THREE.SkinnedMesh;
    Cap: THREE.SkinnedMesh;
    Eye_L: THREE.SkinnedMesh;
    Eye_R: THREE.SkinnedMesh;
    Face: THREE.SkinnedMesh;
    Glasses: THREE.SkinnedMesh;
    Hair: THREE.SkinnedMesh;
    Pants1: THREE.SkinnedMesh;
    Shirt: THREE.SkinnedMesh;
    Shoes: THREE.SkinnedMesh;
    Teeth_Lower: THREE.SkinnedMesh;
    Teeth_Upper: THREE.SkinnedMesh;
    Tongue: THREE.SkinnedMesh;
    Hips: THREE.Bone;
  };
  materials: {
    M_Body2: THREE.MeshStandardMaterial;
    M_Accessories2: THREE.MeshStandardMaterial;
    M_Hair2: THREE.MeshStandardMaterial;
    M_Clothes2: THREE.MeshStandardMaterial;
  };
  animations: GLTFAction[];
};

interface GLTFAction extends THREE.AnimationClip {
  name: ActionName;
}

type ActionName = "Armature|mixamo.com|Layer0";
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export function Model({
  props,
  setHover,
}: {
  props?: JSX.IntrinsicElements["group"];
  setHover: any;
}) {
  const group = useRef<THREE.Group>(null);
  const { nodes, materials, animations } = useGLTF("/Model_Cara.gltf") as any;
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    actions["Armature|mixamo.com|Layer0"]?.play();
  });
  return (
    <Suspense fallback={null}>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <group
            name="Armature"
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.011}
            position={[-0.53, 0.02, -0.56]}
          >
            <primitive object={nodes.Hips} />
            <skinnedMesh
              name="Arms"
              geometry={nodes.Arms.geometry}
              material={materials.M_Body2}
              skeleton={nodes.Arms.skeleton}
            />
            <skinnedMesh
              name="B"
              geometry={nodes.B.geometry}
              material={materials.M_Accessories2}
              skeleton={nodes.B.skeleton}
            />
            <skinnedMesh
              name="Cap"
              geometry={nodes.Cap.geometry}
              material={materials.M_Accessories2}
              skeleton={nodes.Cap.skeleton}
            />
            <skinnedMesh
              name="Eye_L"
              geometry={nodes.Eye_L.geometry}
              material={materials.M_Body2}
              skeleton={nodes.Eye_L.skeleton}
            />
            <skinnedMesh
              name="Eye_R"
              geometry={nodes.Eye_R.geometry}
              material={materials.M_Body2}
              skeleton={nodes.Eye_R.skeleton}
            />
            <skinnedMesh
              name="Face"
              geometry={nodes.Face.geometry}
              material={materials.M_Body2}
              skeleton={nodes.Face.skeleton}
            />
            <skinnedMesh
              name="Glasses"
              geometry={nodes.Glasses.geometry}
              material={materials.M_Accessories2}
              skeleton={nodes.Glasses.skeleton}
            />
            <skinnedMesh
              name="Hair"
              geometry={nodes.Hair.geometry}
              material={materials.M_Hair2}
              skeleton={nodes.Hair.skeleton}
            />
            <skinnedMesh
              name="Pants1"
              geometry={nodes.Pants1.geometry}
              material={materials.M_Clothes2}
              skeleton={nodes.Pants1.skeleton}
            />
            <skinnedMesh
              name="Shirt"
              geometry={nodes.Shirt.geometry}
              material={materials.M_Clothes2}
              skeleton={nodes.Shirt.skeleton}
            />
            <skinnedMesh
              name="Shoes"
              geometry={nodes.Shoes.geometry}
              material={materials.M_Clothes2}
              skeleton={nodes.Shoes.skeleton}
            />
            <skinnedMesh
              name="Teeth_Lower"
              geometry={nodes.Teeth_Lower.geometry}
              material={materials.M_Body2}
              skeleton={nodes.Teeth_Lower.skeleton}
            />
            <skinnedMesh
              name="Teeth_Upper"
              geometry={nodes.Teeth_Upper.geometry}
              material={materials.M_Body2}
              skeleton={nodes.Teeth_Upper.skeleton}
            />
            <skinnedMesh
              name="Tongue"
              geometry={nodes.Tongue.geometry}
              material={materials.M_Body2}
              skeleton={nodes.Tongue.skeleton}
            />
          </group>
        </group>
      </group>
    </Suspense>
  );
}

useGLTF.preload("/Model_Cara.gltf");
