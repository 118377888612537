import MailchimpSubscribe from "react-mailchimp-subscribe";

export default function Footer() {
  return (
    <div className={"h-[5%] flex justify-center items-center "}>
      <MailchimpSubscribe
        url={
          "https://labyle.us6.list-manage.com/subscribe/post?u=307c2313d421946aaf2459e83&amp;id=7c9b085666&amp;f_id=006815e3f0"
        }
        render={({ subscribe, status, message }: any) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData: any) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
}

const CustomForm = ({ status, message, onValidated }: any) => {
  let email: HTMLInputElement | null, name: HTMLInputElement | null;
  const submit = () =>
    email &&
    name &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      NAME: name.value,
    });

  return (
    <div className={"items-center justify-center mb-10"}>
      {(status !== "sending" || status !== "error" || status !== "success") && (
        <div>Subscribe to our Newsletter</div>
      )}
      <div className={"flex bg-[#efefef] border-r-2"}>
        {status === "sending" && (
          <div style={{ color: "blue" }}>sending...</div>
        )}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status !== "sending" && status !== "error" && status !== "success" && (
          <>
            <input
              ref={(node) => (name = node)}
              type="text"
              placeholder="Your name"
            />
            <input
              ref={(node) => (email = node)}
              type="email"
              placeholder="Your email"
            />
            <button onClick={submit}>Submit</button>
          </>
        )}
      </div>
    </div>
  );
};
