/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useEffect, useRef, useState } from "react";
import { Box, useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { Clock } from "three";
import { Model as MenuIcon1_Listen } from "./icons/Speaker";

type GLTFResult = GLTF & {
  nodes: {
    Mesh_Mesh_head_geo001_lambert2SG001: THREE.Mesh;
  };
  materials: {
    ["lambert2SG.001"]: THREE.MeshStandardMaterial;
  };
};
export function Model(props?: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("Menu1.glb") as any;
  const [hover, setHover] = useState(false);

  return (
    <group
      {...props}
      dispose={null}
      scale={0.01}
      rotation={[45, 0, 0]}
      position={hover ? [-2.8, 0.65, 0.1] : [-2.8, 0.6, 0.1]}
    >
      <MenuIcon1_Listen />
      <group
        rotation={[-Math.PI / 2, 0, 0]}
        onPointerOver={() => {
          setHover(true);
        }}
        onPointerOut={() => {
          setHover(false);
        }}
      >
        <Box scale={[100, 80, 10]} position={[0, 65, 0]} visible={false}></Box>
        <mesh
          geometry={nodes.Mesh_Mesh_head_geo001_lambert2SG001.geometry}
          material={materials["lambert2SG.001"]}
          visible={hover}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/Menu1.glb");
