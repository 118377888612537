import {
  FaTiktok,
  FaInstagram,
  FaSpotify,
  FaYoutube,
  FaTwitter,
  FaDiscord,
} from "react-icons/fa";

export default function Header() {
  return (
    <div
      className={
        "h-[5%] bg-gradient-to-b from-[#db2778] to-[#ec489a] flex justify-center items-center gap-10 px-4"
      }
    >
      <FaTiktok
        className={"h-95% aspect-square cursor-pointer"}
        onClick={() => {
          window.open(
            "https://www.tiktok.com/@labylemusic",
            "_blank",
            "noopener,noreferrer"
          );
        }}
      />
      <FaInstagram
        className={"h-95% aspect-square"}
        onClick={() => {
          window.open(
            "https://www.instagram.com/labylemusic/",
            "_blank",
            "noopener,noreferrer"
          );
        }}
      />
      <FaSpotify
        className={"h-95% aspect-square"}
        onClick={() => {
          window.open(
            "https://open.spotify.com/artist/6yWynwuKoYfoNCVpceBsGu?si=zVE6_CJ7RXGRauv7bBne6Q",
            "_blank",
            "noopener,noreferrer"
          );
        }}
      />
      <FaYoutube
        className={"h-95% aspect-square"}
        onClick={() => {
          window.open(
            "https://www.youtube.com/@LaByle",
            "_blank",
            "noopener,noreferrer"
          );
        }}
      />
      <FaTwitter
        className={"h-95% aspect-square"}
        onClick={() => {
          window.open(
            "https://twitter.com/labylemusic",
            "_blank",
            "noopener,noreferrer"
          );
        }}
      />
      <FaDiscord
        className={"h-95% aspect-square"}
        onClick={() => {
          window.open(
            "https://discord.gg/c95MM5WK",
            "_blank",
            "noopener,noreferrer"
          );
        }}
      />
    </div>
  );
}
