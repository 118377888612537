/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef, useState } from "react";
import { Box, useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { Model as MenuIcon4_Contact } from "./icons/Mail";

type GLTFResult = GLTF & {
  nodes: {
    Mesh_Mesh_head_geo001_lambert2SG001: THREE.Mesh;
  };
  materials: {
    ["lambert2SG.001"]: THREE.MeshStandardMaterial;
  };
};

export function Model(props?: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("Menu3.glb") as any;

  const [hover, setHover] = useState(false);

  return (
    <group
      {...props}
      dispose={null}
      scale={0.0105}
      rotation={[45, 0, 0]}
      position={hover ? [2.7, 0.35, 0.1] : [2.7, 0.3, 0.1]}
      onPointerOver={() => {
        setHover(true);
      }}
      onPointerOut={() => {
        setHover(false);
      }}
    >
      <MenuIcon4_Contact />
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <Box
          scale={[180, 100, 10]}
          position={[0, -20, 0]}
          visible={false}
        ></Box>
        <mesh
          geometry={nodes.Mesh_Mesh_head_geo001_lambert2SG001.geometry}
          material={materials["lambert2SG.001"]}
          visible={hover}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/Menu4.glb");
