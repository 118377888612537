/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useRef, useState } from "react";
import { Box, useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { Model as MenuIcon3_Shows } from "./icons/Ticket";

type GLTFResult = GLTF & {
  nodes: {
    Mesh_Mesh_head_geo001_lambert2SG001: THREE.Mesh;
  };
  materials: {
    ["lambert2SG.001"]: THREE.MeshStandardMaterial;
  };
};

export function Model(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("/Menu3_Show.glb") as any;
  const [hover, setHover] = useState(false);
  return (
    <group
      {...props}
      dispose={null}
      scale={0.011}
      rotation={[45, 0, 0]}
      position={hover ? [2.9, 1.85, -0.8] : [2.9, 1.8, -0.8]}
      onPointerOver={() => {
        setHover(true);
      }}
      onPointerOut={() => {
        setHover(false);
      }}
    >
      <MenuIcon3_Shows />
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <Box scale={[130, 80, 10]} position={[0, -70, 0]} visible={false}></Box>
        <mesh
          geometry={nodes.Mesh_Mesh_head_geo001_lambert2SG001.geometry}
          material={materials["lambert2SG.001"]}
          visible={hover}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/Menu3_Show.glb");
